import { ReactComponent as FeaturedStores } from '../../../assets/images/icons/forSlider/featuredStores.svg';
import { ReactComponent as AllStores } from '../../../assets/images/icons/forSlider/allStores.svg';
import { ReactComponent as Fashion } from '../../../assets/images/icons/forSlider/fashion.svg';
import { ReactComponent as Entertainment } from '../../../assets/images/icons/forSlider/entertainment.svg';
import { ReactComponent as Electronics } from '../../../assets/images/icons/forSlider/electronics.svg';
import { ReactComponent as ESim } from '../../../assets/images/icons/forSlider/eSim.svg';
import { ReactComponent as FoodGrocery } from '../../../assets/images/icons/forSlider/foodGrocery.svg';
import { ReactComponent as BabyKidsToys } from '../../../assets/images/icons/forSlider/babyKidsToys.svg';
import { ReactComponent as HomeGarden } from '../../../assets/images/icons/forSlider/homeGarden.svg';
import { ReactComponent as Books } from '../../../assets/images/icons/forSlider/books.svg';
import { ReactComponent as BeautyHealth } from '../../../assets/images/icons/forSlider/beautyHealth.svg';
import { ReactComponent as SportsOutdoor } from '../../../assets/images/icons/forSlider/sportsOutdoor.svg';
import { ReactComponent as Auto } from '../../../assets/images/icons/forSlider/auto.svg';
import { ReactComponent as PetSupplies } from '../../../assets/images/icons/forSlider/petSupplies.svg';
import { ReactComponent as HardwareTools } from '../../../assets/images/icons/forSlider/hardwareTools.svg';
import { ReactComponent as TopUpArrow } from '../../../assets/images/icons/forSlider/topUpArrow.svg';

export const homeTabsData = (t) => [
  {
    name: t('BUY_TOP_UP_PIN'),
    icon: <TopUpArrow />,
    value: 'topUpPin',
  },
  {
    name: t('FEATURES_STORES'),
    icon: <FeaturedStores />,
    value: 'favoritesPosition',
  },
  {
    name: t('ALL_STORES'),
    icon: <AllStores />,
    value: 'title',
  },
  {
    name: t('E_SIMS'),
    icon: <ESim />,
    value: 'eSims',
  },
  {
    name: t('FASHION'),
    icon: <Fashion />,
    value: 'Fashion',
  },
  {
    name: t('ENTERTAINMENT'),
    icon: <Entertainment />,
    value: 'Entertainment',
  },
  {
    name: t('ELECTRONICS'),
    icon: <Electronics />,
    value: 'Electronics',
  },
  {
    name: t('FOOD_AND_GROCERY'),
    icon: <FoodGrocery />,
    value: 'Food & Grocery',
  },
  {
    name: t('BABY_KIDS_TOYS'),
    icon: <BabyKidsToys />,
    value: 'Baby, Kids & Toys',
  },
  {
    name: t('BOOKS'),
    icon: <Books />,
    value: 'Books',
  },
  {
    name: t('HOME_AND_GARDEN'),
    icon: <HomeGarden />,
    value: 'Home & Garden',
  },
  {
    name: t('BEAUTY_AND_HEALTH'),
    icon: <BeautyHealth />,
    value: 'Beauty & Health',
  },
  {
    name: t('SPORTS_AND_OUTDOOR'),
    icon: <SportsOutdoor />,
    value: 'Sports & Outdoor',
  },
  {
    name: t('AUTO'),
    icon: <Auto />,
    value: 'Auto',
  },
  {
    name: t('PET_SUPPLIES'),
    icon: <PetSupplies />,
    value: 'Pet Supplies',
  },
  {
    name: t('HARDWARE_AND_TOOLS'),
    icon: <HardwareTools />,
    value: 'Hardware & Tools',
  },
];
