import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { memo, Suspense, useCallback, useMemo } from 'react';
import CheckboxesFilter from '../CheckboxesFilter';
import { ReactComponent as LeftArrow } from '../../../assets/images/icons/forSlider/leftArrow.svg';
import { ReactComponent as RightArrow } from '../../../assets/images/icons/forSlider/rightArrow.svg';
import { ReactComponent as Filter } from '../../../assets/images/icons/filter.svg';
import { ReactComponent as ResetFilter } from '../../../assets/images/icons/resetFilter.svg';
import { useSearchParamByKey } from '../../../hooks/useSearchParamByKey';
import { homeTabsData as getTabs } from './tabsData';

const StoreIconSlider = ({
  setPage,
  step,
  setStep,
  setIsShowFilter,
  isShowFilter,
  filters,
  setFilters,
  currentLanguage,
}) => {
  const classes = useStyles({ currentLanguage });
  const { t } = useTranslation();
  const { getParam, hasParam, setParam, delParam } = useSearchParamByKey();

  const handleChange = useCallback(
    (event, newValue) => {
      if (newValue === 'topUpPin') {
        delParam();
      } else {
        setParam(newValue);
      }
      setPage(1);
    },
    [setParam, delParam, setPage],
  );

  const handleClickLeftArrow = useCallback(() => {
    if (step === 3) {
      setParam('Food & Grocery');
      setStep(2);
    } else if (step === 2) {
      setParam('Entertainment');
      setStep(1);
    } else if (step === 1) {
      delParam();
      setStep(0);
    }
    setPage(1);
  }, [step, setParam, delParam, setStep, setPage]);

  const handleClickRightArrow = useCallback(() => {
    if (step === 0) {
      setParam('Books');
      setStep(1);
    } else if (step === 1) {
      setParam('Pet Supplies');
      setStep(2);
    } else if (step === 2) {
      setParam('Hardware & Tools');
      setStep(3);
    }
    setPage(1);
  }, [step, setParam, setStep, setPage]);

  const tabs = useMemo(() => getTabs(t), [t]);

  return (
    <div className={classes.container}>
      <div className={classes.carouselWrapper}>
        {step !== 0 && (
          <div
            className={classes.arrowContainer}
            onClick={handleClickLeftArrow}
          >
            <div className={classes.leftArrowIcon}>
              <LeftArrow />
            </div>
          </div>
        )}
        <div className={classes.sliderContainer}>
          <div className={classes.tabsContainer}>
            <Tabs
              classes={{ root: classes.customTabs }}
              value={hasParam ? getParam : 'topUpPin'}
              onChange={handleChange}
              textColor='secondary'
              TabIndicatorProps={{
                className: classes.tabIndicator,
              }}
            >
              {tabs.map((el) => (
                <Tab
                  value={el.value}
                  label={el.name}
                  id={el.value}
                  key={el.name}
                  icon={<div>{el.icon}</div>}
                />
              ))}
            </Tabs>
          </div>
        </div>

        <div className={classes.arrowContainer}>
          <div
            className={`${classes.rightArrowIcon} ${
              step === 3 ? classes.hidden : ''
            }`}
            onClick={handleClickRightArrow}
          >
            <RightArrow />
          </div>
          {isShowFilter && filters.length > 0 ? (
            <div
              className={`${classes.filter} ${classes.filledFilter}`}
              onClick={() => setFilters([])}
            >
              <ResetFilter />
              <span className={classes.filterTitle}>{t('FILTER')}</span>
            </div>
          ) : (
            <div
              className={`${classes.filter} ${
                isShowFilter && classes.activeFilter
              } ${!hasParam && classes.disableArea}`}
              onClick={() => setIsShowFilter((prev) => !prev)}
            >
              <Filter />
              <span className={classes.filterTitle}>{t('FILTER')}</span>
            </div>
          )}
        </div>
      </div>
      {isShowFilter && (
        <Suspense
          fallback={
            <div className={classes.filterFallback}>{t('LOADING')}...</div>
          }
        >
          <CheckboxesFilter
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
        </Suspense>
      )}
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  tabIndicator: {
    backgroundColor: 'black !important',
    color: 'red !important',
  },

  carouselWrapper: {
    height: '120px',
    padding: '40px 72px  0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      padding: '24px 16px  0px',
    },
  },
  tabsContainer: {
    maxWidth: '950px',
  },
  customTabs: {
    '& .MuiTab-root': {
      letterSpacing: '0.02em',
      fontWeight: '500',
      fontSize: '16px',
      fontStyle: 'normal',
      fontFamily: 'Source Sans Pro, sans-serif',
      textTransform: 'none',
      display: 'flex',
      flexDirection: 'row',
      padding: '0',
      marginRight: '32px',
    },
    '& .MuiTabs-scroller': {
      [theme.breakpoints.down('md')]: {
        overflowX: 'scroll!important',
      },
    },
    '& .MuiTab-root.Mui-selected': {
      color: 'black',
    },
    '& .MuiTab-root.MuiTab-labelIcon': {
      marginRight: (currentLanguage) => {
        if (currentLanguage.currentLanguage === 'fr') return '23px';
        return '34px';
      },
      letterSpacing: (currentLanguage) => {
        if (currentLanguage.currentLanguage === 'fr') return '-.02em';
        return '0';
      },
    },
    '& .MuiTab-root>.MuiTab-iconWrapper': {
      width: '48px',
      height: '48px',
      margin: '0px',
      backgroundColor: '#FAFAFA',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '100px',
      marginRight: '14px',
    },
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    position: 'relative',
    minWidth: '90px',
    zIndex: '11',
    [theme.breakpoints.down('md')]: {
      minWidth: '41px',
    },
  },
  sliderContainer: {
    width: '930px',
    overflow: 'hidden',
  },
  leftArrowIcon: {
    width: '36px',
    height: '36px',
    borderRadius: '33px',
    border: '1px solid #EAEAEA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  rightArrowIcon: {
    minWidth: '36px',
    minHeight: '36px',
    borderRadius: '33px',
    border: '1px solid #EAEAEA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginInline: '31px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  filter: {
    minWidth: '100px',
    height: '48px',
    border: '1px solid #EAEAEA',
    borderRadius: '34px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      border: 'none',
      minWidth: 16,
      marginLeft: '30px',
      padding: 2,
    },
    [theme.breakpoints.down('sm')]: {
      width: '20px',
      marginLeft: 'auto',
    },
  },
  filterTitle: {
    fontWeight: '600',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '125%',
    fontFamily: 'Inter',
    letterSpacing: '0.05em',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  activeFilter: {
    outline: '3px solid #33CC55',
  },
  filledFilter: {
    background: '#33CC55',
    outline: '5px solid #33CC55',
    outlineOffset: '-2px',
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      aspectRatio: '1/1',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  disableArea: {
    pointerEvents: 'none',
    filter: 'opacity(0.6)',
  },
  filterFallback: {
    fontFamily: 'Inter',
    textAlign: 'center',
    padding: '0.5rem',
  },
}));
export default memo(StoreIconSlider);
