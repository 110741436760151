import { memo } from 'react';
import { makeStyles } from '@material-ui/core';

const Slide = ({ slide, onClick }) => {
  const classes = useStyles();

  return (
    <div
      key={slide.file}
      className={classes.slide}
      onClick={() => onClick(slide.url)}
    >
      <img
        src={slide.file}
        alt='slide-img'
        className={classes.slideImage}
        width={920}
        height={308}
      />
    </div>
  );
};

export default memo(Slide);

const useStyles = makeStyles((theme) => ({
  slide: {
    maxWidth: '1280px',
    height: '304px',
  },
  slideImage: {
    maxWidth: '1280px',
    width: '100%',
    height: '308px',
    objectFit: 'fill',
    [theme.breakpoints.down(745)]: {
      objectFit: 'contain',
    },
  },
}));
