import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import StoreIconSlider from '../components/lib/StoreIconSlider/StoreIconSlider';
import HowItWorksCarousel from '../components/lib/howItWorksCarousel/Carousel';
import { reset, setIsShowHowItWorks } from '../redux/slices/storesSlice';
import { carrierReset } from '../redux/slices/carriersSlice';
import { Carousel } from '../components/lib/Carousel';
import { useSearchParamByKey } from '../hooks/useSearchParamByKey';
import { useEnvironment } from '../hooks/useEnvironment';
import { canonicalUrls } from '../utils/constants';

const StoresListContainer = React.lazy(() =>
  import('../components/StoresListContainer'),
);
const Carriers = React.lazy(() => import('./Carriers'));

const Home = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [page, setPage] = useState(1);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [filters, setFilters] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const { showHowItWorks } = useSelector((state) => state.stores);
  const { getParam: categoryId, hasParam } = useSearchParamByKey();
  const { isProd } = useEnvironment();

  useEffect(() => {
    if (i18n.language !== currentLanguage) {
      setPage(() => 1);
      setCurrentLanguage(i18n.language);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (filters.length > 0) {
      setFilters([]);
    }
    if (page > 1) {
      dispatch(reset());
      dispatch(carrierReset());
    }
    setPage(1);
    setIsShowFilter(false);
  }, [categoryId]);

  return (
    <>
      <Helmet>
        <title>{t('DASHBOARD_TITLE_SEO')}</title>
        {isProd && (
          <meta name='description' content={t('DASHBOARD_DESCRIPTION_SEO')} />
        )}
        {isProd && (
          <meta name='keywords' content={t('DASHBOARD_KEYWORDS_SEO')} />
        )}
        {isProd && <meta name='robots' content='index, follow' />}
        {isProd && <link rel='canonical' href={canonicalUrls.home} />}
        {!isProd && <meta name='robots' content='noindex, nofollow' />}
      </Helmet>
      <div className={classes.homeContainer}>
        <h1 className={classes.title}>{t('BUY_PREPAID_MOBILE')}</h1>
        <Carousel />
        <StoreIconSlider
          step={step}
          setStep={setStep}
          setPage={setPage}
          setIsShowFilter={setIsShowFilter}
          isShowFilter={isShowFilter}
          filters={filters}
          setFilters={setFilters}
          currentLanguage={currentLanguage}
        />
        <div className={classes.bodyContainer}>
          <div className={classes.cardsContainer}>
            <div className={classes.cardsWrapper}>
              {hasParam ? (
                <StoresListContainer
                  page={page}
                  setPage={setPage}
                  filters={filters}
                  categoryId={categoryId}
                  currentLanguage={currentLanguage}
                />
              ) : (
                <Carriers
                  page={page}
                  setPage={setPage}
                  currentLanguage={currentLanguage}
                />
              )}
              <div>
                <div>
                  <div className={classes.carouselContainer}>
                    {showHowItWorks && (
                      <HowItWorksCarousel
                        onClose={() => dispatch(setIsShowHowItWorks(false))}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    fontFamily: 'Inter',
    padding: '0 16px',
    color: '#4A4A4A',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3em',
    },
  },
  bodyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardsWrapper: {
    width: '1280px',
    position: 'relative',
    padding: '0px 72px 0px',
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'scroll',

    smooth: 'easeInOutQuart',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(105 255 54 / 10%)',
      outline: '1px solid slategrey',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px 0px',
      height: '100%',
    },
  },
  carouselContainer: {
    left: 0,
    bottom: '20px',
    position: 'fixed',
    marginLeft: '24px',
  },
}));
