import { useSearchParams } from 'react-router-dom';

export const useSearchParamByKey = (key = 'category') => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParam = () => searchParams.get(key);
  const hasParam = () => searchParams.has(key);
  const setParam = (value) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const delParam = () => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  return {
    getParam: getParam(),
    hasParam: hasParam(),
    setParam,
    delParam,
  };
};
