import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { ScrollToTop } from './components/lib/ScrollToTop';
import Home from './pages/Home';

// import HeaderLayout from './components/layouts/HeaderLayout';
// import NotFoundPage from './pages/NotFound';
import Loader from './components/lib/Loader';
import AuthLayout from './components/layouts/AuthLayout';
import ProtectedRoutesLayout from './components/layouts/ProtectedRoutesLayout';
import { store } from './redux/store';

const SignUp = React.lazy(() => import('./pages/SignUp/SignUp'));
const LogIn = React.lazy(() => import('./pages/LogIn/LogIn'));
const ResetPasswordByPhone = React.lazy(() =>
  import('./pages/ResetPasswordByPhone/ResetPasswordByPhone'),
);
const ResetPasswordByEmail = React.lazy(() =>
  import('./pages/ResetPasswordByEmail/ResetPasswordByEmail'),
);
// const Home = React.lazy(() => import('./pages/Home'));
const Store = React.lazy(() => import('./pages/Store'));
const Cashback = React.lazy(() => import('./pages/Cashback/Cashback'));
const MissingTransaction = React.lazy(() =>
  import('./pages/MissingTransaction/MissingTransaction'),
);
const PersonalInformation = React.lazy(() =>
  import('./pages/PersonalInformation'),
);
// const Terms = React.lazy(() => import('./pages/Terms'));
// const Policy = React.lazy(() => import('./pages/Policy'));
const InfoPages = React.lazy(() => import('./pages/InfoPages'));
const Blog = React.lazy(() => import('./pages/Blog'));
const BlogDetails = React.lazy(() => import('./pages/Blog/BlogDetails'));
const Carrier = React.lazy(() => import('./pages/Carrier'));
const Checkout = React.lazy(() => import('./pages/Checkout'));
const BillingInformationForm = React.lazy(() =>
  import('./components/Checkout/BillingInformationForm'),
);
const PaymentFormWrapper = React.lazy(() =>
  import('./components/Checkout/PaymentFormWrapper'),
);
const Success = React.lazy(() => import('./pages/Success'));

const App = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        <ToastContainer
          position='top-center'
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path='/'>
              {/* public routes */}
              <Route path='/signup' element={<SignUp />} />
              <Route path='/login' element={<LogIn />} />
              <Route
                path='/reset_password_by_phone'
                element={<ResetPasswordByPhone />}
              />
              <Route
                path='/reset_password_by_email'
                element={<ResetPasswordByEmail />}
              />
              {/* public or protected routes */}
              <Route path='/' element={<AuthLayout />}>
                <Route path='/contact_us' element={<InfoPages />} />
                <Route path='/how_it_works' element={<InfoPages />} />
                <Route path='/terms_and_conditions' element={<InfoPages />} />
                <Route path='/privacy_policy' element={<InfoPages />} />
                <Route path='/faq' element={<InfoPages />} />
                <Route path='/privacy_policy' element={<InfoPages />} />
                {/* <Route path='/info-page/:type' element={<InfoPages />} /> */}
                <Route path='/telecoms' element={<InfoPages />} />
                <Route path='/blog' element={<Blog />} />
                <Route path='/blog/:blogSlug' element={<BlogDetails />} />
                <Route path='/' element={<Home />} />
                <Route path='/buy-topup-pin/:id' element={<Carrier />} />
                <Route path='/store/:id' element={<Store />} />
                <Route path='/cashback' element={<Cashback />} />
                <Route element={<ProtectedRoutesLayout />}>
                  <Route
                    path='/missing_transaction'
                    element={<MissingTransaction />}
                  />
                  <Route
                    path='/personal_info'
                    element={<PersonalInformation />}
                  />
                  <Route
                    path='/buy-topup-pin/:id/value/:fixedValueId/checkout'
                    element={<Checkout />}
                  >
                    <Route path='' element={<BillingInformationForm />} />
                    <Route path='payment' element={<PaymentFormWrapper />} />
                  </Route>
                  <Route path='/success' element={<Success />} />
                </Route>
              </Route>
              {/* catch all */}
              <Route path='*' element={<Navigate to='/' replace />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </Provider>
  );
};

export default App;
