export const purchaseTypes = [
  { value: 'mobile', forTranslate: 'MOBILE' },
  { value: 'desktop', forTranslate: 'DESKTOP' },
];
export const paymentMethods = [
  { value: 'credit card', forTranslate: 'CREDIT_CARD' },
  { value: 'debit card', forTranslate: 'DEBIT_CARD' },
  { value: 'installment payments', forTranslate: 'INSTALLMENT_PAYMENTS' },
];

export const countries = [{ name: 'Canada', countryCode: 'CA' }];
export const canadianProvinces = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon',
];

export const canonicalUrls = {
  howItWorks: 'https://www.telcorewards.ca/how_it_works',
  signUp: 'https://www.telcorewards.ca/signup',
  blog: 'https://www.telcorewards.ca/blog',
  faq: 'https://www.telcorewards.ca/faq',
  home: 'https://www.telcorewards.ca/',
  Bell: 'https://www.telcorewards.ca/buy-topup-pin/Bell',
  public_mobile: 'https://www.telcorewards.ca/buy-topup-pin/public_mobile',
  telus: 'https://www.telcorewards.ca/buy-topup-pin/telus',
  chatr: 'https://www.telcorewards.ca/buy-topup-pin/chatr',
  koodo: 'https://www.telcorewards.ca/buy-topup-pin/koodo',
  'freedom-mobile': 'https://www.telcorewards.ca/buy-topup-pin/freedom-mobile',
  fido: 'https://www.telcorewards.ca/buy-topup-pin/fido',
  rogers: 'https://www.telcorewards.ca/buy-topup-pin/rogers',
  'lucky-mobile': 'https://www.telcorewards.ca/buy-topup-pin/lucky-mobile',
  'virgin-plus': 'https://www.telcorewards.ca/buy-topup-pin/virgin-plus',
  sasktel: 'https://www.telcorewards.ca/buy-topup-pin/sasktel',
  pc_mobile: 'https://www.telcorewards.ca/buy-topup-pin/pc_mobile',
};
