import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@mui/material';
// eslint-disable-next-line
import 'slick-carousel/slick/slick.css';
// eslint-disable-next-line
import 'slick-carousel/slick/slick-theme.css';

import { useTranslation } from 'react-i18next';
import { getBanners } from '../../../redux/slices/bannerSlice';
import './styles.scss';
import { debounce } from '../../../utils/helpers';
import Slide from './Slide';

const SETTINGS = {
  dots: true,
  infinite: true,
  autoplay: true,
  arrows: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const Carousel = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const lang = i18n.language;
  const { banners, isLoading } = useSelector((state) => state.banner);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    dispatch(
      getBanners({ languageCode: lang, type: isMobile ? 'mobile' : 'desktop' }),
    );
  }, [dispatch, lang, isMobile]);

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setIsMobile(window.innerWidth < 600);
    }, 600);

    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  const handleSlideClick = useCallback((url) => {
    if (url) {
      window.open(url);
    }
  }, []);

  if (isLoading) {
    return (
      <section className={classes.loaderWrapper}>
        <Skeleton
          variant='rounded'
          width={1136}
          height={302}
          animation='wave'
          classes={{
            root: classes.skeletonRoot,
          }}
        />
      </section>
    );
  }

  return (
    <section className={classes.sliderWrapper}>
      <Slider {...SETTINGS}>
        {banners?.map((slide) => (
          <Slide key={slide.file} slide={slide} onClick={handleSlideClick} />
        ))}
      </Slider>
    </section>
  );
};

const useStyles = makeStyles(() => ({
  skeletonRoot: {
    borderRadius: '16px',
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '1136px',
    height: '304px',
    margin: '50px auto 0',
    padding: '0 72px',
  },
  sliderWrapper: {
    maxWidth: '1136px',
    height: '304px',
    margin: '50px auto 0',
    padding: '0 72px',
    cursor: 'pointer',
  },
}));
